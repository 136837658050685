exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-automation-tsx": () => import("./../../../src/pages/automation.tsx" /* webpackChunkName: "component---src-pages-automation-tsx" */),
  "component---src-pages-career-index-tsx": () => import("./../../../src/pages/career/index.tsx" /* webpackChunkName: "component---src-pages-career-index-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-event-tsx": () => import("./../../../src/pages/event.tsx" /* webpackChunkName: "component---src-pages-event-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-implementation-tsx": () => import("./../../../src/pages/implementation.tsx" /* webpackChunkName: "component---src-pages-implementation-tsx" */),
  "component---src-pages-import-tsx": () => import("./../../../src/pages/import.tsx" /* webpackChunkName: "component---src-pages-import-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-privacy-mobile-tsx": () => import("./../../../src/pages/privacy-mobile.tsx" /* webpackChunkName: "component---src-pages-privacy-mobile-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-bootsman-click-tsx": () => import("./../../../src/pages/product-bootsman-click.tsx" /* webpackChunkName: "component---src-pages-product-bootsman-click-tsx" */),
  "component---src-pages-product-bootsman-tsx": () => import("./../../../src/pages/product-bootsman.tsx" /* webpackChunkName: "component---src-pages-product-bootsman-tsx" */),
  "component---src-pages-product-stlinux-tsx": () => import("./../../../src/pages/product-stlinux.tsx" /* webpackChunkName: "component---src-pages-product-stlinux-tsx" */),
  "component---src-pages-product-stmonitor-tsx": () => import("./../../../src/pages/product-stmonitor.tsx" /* webpackChunkName: "component---src-pages-product-stmonitor-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-vacancy-tsx": () => import("./../../../src/pages/vacancy.tsx" /* webpackChunkName: "component---src-pages-vacancy-tsx" */)
}

